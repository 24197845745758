import React from "react";
import Lottie from "react-lottie";
import animationData from "../../animations/loadingWhite.json";

import "../main/css/home.css";
import "../main/css/loader.css";
import "../main/css/wallpaper.css";

export default function App() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="homeContainer">
      <div id="wallpaper" alt="wallpaper"></div>

      <div className="animationContainer">
        <Lottie
          id="animation"
          options={defaultOptions}
          isClickToPauseDisabled={true}
          height={330}
          width={330}
        />
      </div>

      <h1 className="loadingTitle">Estamos preparando o café</h1>
    </div>
  );
}

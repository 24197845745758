import {
  storeLocalUser,
  loadLocalUser,
  cleanLocalUser,
} from "../lib/localStorageFunctions";
import { v4 as uuid } from "uuid";

const login = (username, password) => {
  var body = null;
  if (username) body = { username, password };
  else body = { authData: { anonymous: { id: uuid() } } };

  return fetch(
    process.env.REACT_APP_PARSE_URL + (username ? "/login" : "/users"),
    {
      headers: {
        "X-Parse-Application-Id": process.env.REACT_APP_PARSE_ID,
        "X-Parse-Rest-Api-Key": process.env.REACT_APP_REST_API_KEY,
      },
      method: "POST",
      body: JSON.stringify(body),
    }
  ).then((r) => r.json());
};

const getRoles = (token, userId) =>
  fetch(
    process.env.REACT_APP_PARSE_URL +
      '/classes/_Role?where={"users":{"__type":"Pointer","className":"_User","objectId":"' +
      userId +
      '"}}',
    {
      headers: {
        "X-Parse-Application-Id": process.env.REACT_APP_PARSE_ID,
        "X-Parse-REST-API-Key": process.env.REACT_APP_REST_API_KEY,
        "X-Parse-Session-Token": token,
      },
    }
  ).then((r) => r.json());

function User() {
  this.objectId = "";
  this.username = "";
  this.token = "";
  this.accessLevel = [];

  this.becomeUser = async (objectId, username, token, accessLevel) => {
    storeLocalUser({ objectId, username, token, accessLevel });
    this.objectId = objectId;
    this.username = username;
    this.token = token;
    this.accessLevel = accessLevel;
    return true;
  };

  this.login = (username, password) =>
    login(username, password).then((user) =>
      getRoles(user.sessionToken, user.objectId).then(({ results }) =>
        this.becomeUser(
          user.objectId,
          user.username,
          user.sessionToken,
          results.map((role) => role.name)
        )
      )
    );

  this.init = async () => {
    const userData = loadLocalUser();
    if (userData) {
      return this.becomeUser(...Object.values(userData));
    } else
      return login().then((r) =>
        this.becomeUser(r.objectId, r.username, r.sessionToken, [])
      );
  };

  this.logout = async () => {
    this.becomeUser("", "", "", []);
    cleanLocalUser();
    return true;
  };
}

const user = new User();

export default user;

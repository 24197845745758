import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import Error from "./components/error/Error";
import Bugsnag from "./configs/bugsnag";

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

ReactDOM.render(
  <ErrorBoundary FallbackComponent={Error}>
    <App />
  </ErrorBoundary>,
  document.getElementById("root")
);

serviceWorkerRegistration.register();

import React from "react";
import Lottie from "react-lottie";
import animationData from "../../animations/error.json";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import "./Error.css";
import "../main/css/wallpaper.css";

export default function App() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div id="wallpaper" className="homeContainer">
      <a href="/">
        <ArrowBackIcon fontSize="large" className="back-icon" />
      </a>
      <div className="animationContainer">
        <Lottie
          id="animation"
          isClickToPauseDisabled={true}
          options={defaultOptions}
          height={400}
          width={400}
        />
      </div>

      <h1 className="animationTitle">
        Um erro foi encontrado <br /> clique no ícone para recarregar a
        aplicação
      </h1>
    </div>
  );
}

import React, { Suspense, lazy } from "react";
import LoadingScreen from "./static/LoadingScreen";
import AuthProvider from "../providers/AuthProvider";

import "./App.css";

const Main = lazy(() => import("./main/Main"));

function App() {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <AuthProvider>
        <Main />
      </AuthProvider>
    </Suspense>
  );
}

export default App;
